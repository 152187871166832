<template>
  <div
    class="px-2  pt-4 pb-4 my-3 mx-5 my-5 h-max-content brown-wGreyMedium rounded bg-white shadow-sm pointer d-flex flex-column justify-content-between  text-grey-medium  w-auto"
  >
    <span class="py-3 mx-3 align-self-center">Zona en construcción</span>

    <span class="py-3 mx-3 align-self-center"
      >Estamos trabajando para traerte más servicios</span
    >

    <inline-svg
      class="align-self-center "
      src="/calendario.svg"
      width="67px"
      height="67px"
      fill="white"
      aria-label="Loading..."
    />
  </div>
</template>

<script>
export default {

}
</script>

<style></style>
